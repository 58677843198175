.chatView {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  flex: 0 0 100%;
  max-height: 100%;
}

.chatView.popout {
  transition: transform 400ms ease-in-out;
  flex: 0 0 440px;
  position: absolute;
  bottom: 0px;
  height: 700px;
  max-height: none;
  width: 440px;
  overflow: hidden;
  border-top-left-radius: var(--border-radius-small);
  border-top-right-radius: var(--border-radius-small);
  box-shadow: -8px 4px 25px -5px rgba(41, 37, 36, 0.15);
}

.chatView.popout.minimized {
  transform: translateY(620px);
}

.chatContainer {
  display: flex;
  position: relative;
  flex: 1 1 1px;
  flex-direction: column-reverse;
  justify-content: flex-start;
  align-items: stretch;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-left: 32px;
  padding-right: 32px;
  background-color: white;
}

.chatContainer.popout {
  padding-left: 16px;
  padding-right: 16px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.chatBottomBarContainer {
  display: flex;
  justify-content: center;
  border-top: 1px solid var(--color-gray-300);
  width: 100%;
  padding: 24px 16px;
  box-sizing: border-box;
  background-color: var(--color-background-primary);
}

.chatBottomBar {
  max-width: 500px;
}

.popoutHeader {
  background-color: var(--color-background-secondary);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-width: 0px;
  box-sizing: border-box;
  padding: 16px;
  gap: 16px;
  border-bottom: 1px solid var(--color-separator-primary);
  height: 92px;
  width: 100%;
}

.avatarLabel {
  user-select: none;
  cursor: pointer;
}

.discussedPatient {
  color: var(--color-text-link);
  cursor: pointer;
  user-select: none;
}

.titleOverride {
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 1024px) {
  .chatContainer {
    padding-left: 15px;
    padding-right: 15px;
  }
  .chatView.popout {
    height: 620px;
  }
}
