.dropdown {
  border-radius: var(--border-radius-small);
  box-shadow: 0px 4px 8px 0px var(--v2-shadow-lg);
  background-color: var(--color-white);
  border: 0.5px solid var(--v2-color-border);
  position: absolute;
  top: calc(100% + 6px);
  overflow: hidden;
  z-index: 9999;
  right: 0;
  box-sizing: border-box;
}

.dropdownScroll::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px;
}

.dropdownScroll::-webkit-scrollbar-thumb {
  background-color: var(--color-gray-600);
  border-radius: 5px;
  min-height: 40px;
}

.dropdownScroll::-webkit-scrollbar-track {
  background-color: var(--color-gray-50);
}

.dropdownOption {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  padding: 0 16px;
  height: 40px;
  background-color: var(--color-white);
  cursor: pointer;
}

.dropdownOption.selected {
  background-color: var(--v2-color-blue-hover);
}

.dropdownOption:hover {
  background-color: var(--v2-color-blue-hover);
  color: var(--color-text-link);
}

.dropdownOption:hover > .dropdownOptionName {
  color: var(--color-text-link);
}

.dropdownOptionNameAndSecondaryText {
  display: flex;
  flex-direction: column;
  user-select: none;
  flex-grow: 1;
}

.dropdownOptionName {
  display: flex;
  align-items: center;
}

.dropdownOptionText {
  font-family: ProximaNova;
  color: var(--v2-color-text-primary);
  font-size: 14px;
  font-weight: 400;
}

.premium {
  padding-left: 4px;
}

.dropdownOptionCheckbox {
  display: none;
}

.dropdownOptionCheckbox:checked + .dropdownOptionCheckmark {
  visibility: visible;
}

.dropdownOptionCheckmark {
  visibility: hidden;
  color: var(--color-text-link);
}

.noResults {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 0 16px;
  height: 44px;
  background-color: var(--color-white);
}

.w100 {
  width: 100%;
}
