.base {
  display: flex;
  flex-direction: row;
  height: 100vh;
  max-width: 100vw;
  overflow-x: hidden;
}

.sideBarContainer {
  flex: 0 0 96px;
}

.popoutChats {
  position: fixed;
  z-index: 9999;
  height: 60px;
  bottom: 0px;
  right: 16px;
  display: flex;
  gap: 16px;
  justify-content: flex-end;
}

.popoutChatContainer {
  position: relative;
  width: 440px;
}

.pageContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  overflow-x: hidden;
  height: 100%;
  flex: 1 1 1px;
  background-color: var(--color-white);
}
