.chatListHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 25px 32px;
  height: 96px;
  flex-shrink: 0;
  box-sizing: border-box;
  border-bottom: 1px solid var(--color-separator-primary);
}

.filter {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 1024px) {
  .chatListHeader {
    padding: 25px 10px 25px 20px;
  }
}
