.base {
  font-family: ProximaNova;
  line-height: var(--line-height-medium);
  letter-spacing: var(--font-tracking-none);
  padding: 0;
  margin: 0;
  white-space: pre-wrap;
  color: var(--color-text-primary);
}

.lg {
  font-size: 18px;
}

.md {
  font-size: 16px;
}

.sm {
  font-size: 14px;
}

.xs {
  font-size: 12px;
  line-height: var(--line-height-reset);
}

.regular {
  font-weight: var(--font-weight-regular);
}

.medium {
  font-weight: var(--font-weight-medium);
}

.bold {
  font-weight: var(--font-weight-bold);
}

.primary {
  color: var(--color-text-primary);
}

.secondary {
  color: var(--color-text-secondary);
}

.link {
  color: var(--color-text-link);
}

.disabled {
  color: var(--color-text-disabled);
}

.white {
  color: var(--color-text-white);
}

.danger {
  color: var(--color-red-600);
}

.inherit {
  color: inherit;
}
