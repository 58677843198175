.lastUpdateHeading {
  margin-right: 32px;
  margin-bottom: 12px;
  width: 326px;
  height: 22px;
}

.lastUpdateBody {
  margin-right: 32px;
  width: 326px;
  height: 21px;
}

.lastUpdateUser {
  display: flex;
  flex-direction: row;
}

.lastUpdateUserNameAndTitle {
  display: flex;
  align-items: center;
  margin-top: 5px;
  margin-left: 8px;
  margin-right: 60px;
  width: 270px;
  height: 18px;
}

.lastUpdateUserAvatar {
  margin-top: 4px;
}
