.upcomingAppointment {
  border-bottom: 1px solid var(--color-separator-primary);
  width: 100%;
}

.heading {
  margin-bottom: 24px;
}

.spinner {
  height: 168px;
}

.modal {
  height: 700px;
}
