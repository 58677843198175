.selectDate {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 32px;
}

.appointmentDropdowns {
  display: flex;
  width: 836px;
  height: 135px;
}

.dropdowns {
  width: 836px;
  height: 135px;
  padding-bottom: 48px;
}
