.notesDetail {
  overflow-y: auto;
  overflow-x: auto;
  height: 100%;
  width: 100%;
}

.author {
  margin-top: 24px;
  margin-bottom: 8px;
}

.date {
  margin-top: 32px;
  margin-bottom: 8px;
}

.notes {
  margin-top: 32px;
  margin-bottom: 8px;
}

.epicNote {
  border: 1px solid var(--color-gray-300);
  box-sizing: border-box;
  border-radius: var(--border-radius-small);
  background-color: var(--color-white);
  padding: 24px;
  width: 98%;
  margin-bottom: 24px;
}

.orders {
  margin-top: 32px;
  margin-bottom: 32px;
  margin-right: 32px;
}
