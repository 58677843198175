.chatHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 0px;
  box-sizing: border-box;
  padding: 20px 32px;
  gap: 16px;
  border-bottom: 1px solid var(--color-separator-primary);
  height: 96px;
  width: 100%;
}

.participantsModal {
  width: 600px;
  height: 700px;
  /* overflow-y: auto; */
}

.participantsList {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.participant {
  padding: 12px 0;
  border-bottom: 1px solid var(--color-separator-primary);
}

.avatarLabel {
  user-select: none;
  cursor: pointer;
}

.discussedPatient {
  color: var(--color-text-link);
  cursor: pointer;
  user-select: none;
}

@media screen and (max-width: 1024px) {
  .chatHeader {
    padding: 20px 15px;
    gap: 10px;
  }
}
