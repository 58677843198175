.noteSection {
  display: flex;
  flex-direction: row;
  padding-top: 48px;
  justify-content: space-between;
}

.noteBox {
  display: flex;
  flex-direction: column;
  min-width: 472px;
  width: 472px;
}
/* 
.descriptionInput {
  margin-bottom: 40px;
} */

.noteTitle {
  padding-bottom: 16px;
}

.authorLog {
  background-color: #fafafa;
  max-width: 300px;
  width: 300px;
  height: 140px;
  text-align: left;
  flex: 1 0 1px;
  padding: 24px;
  margin-left: 64px;
}

.radio {
  padding-right: 10px;
  padding-bottom: 16px;
}

.careGiven {
  display: flex;
  flex-direction: column;
  border-top: 1px solid var(--color-separator-primary);
}

.careGivenTitle {
  padding-bottom: 16px;
  padding-top: 36px;
}

.careOption {
  display: flex;
  flex-direction: row;
}

.reasonBox {
  display: flex;
  flex-direction: column;
  justify-content: left;
  border: 1px solid var(--color-gray-300);
  box-sizing: border-box;
  border-radius: var(--border-radius-small);
  background-color: #f6fbfd;
  padding: 24px;
  width: 836px;
  height: 100%;
  margin-top: 32px;
}

.reasonTitle {
  padding-bottom: 8px;
}

.logs {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.logTitle {
  padding-bottom: 12px;
}
.logText {
  padding-left: 8px;
}

.smAvatarRow {
  display: flex;
  padding-top: 4px;
}

.logAuthor {
  display: flex;
  align-items: center;
  gap: 8px;
}

.attachmentSection {
  display: flex;
  flex-direction: column;
  padding-top: 48px;
  padding-bottom: 36px;
  max-width: 472px;
}

.attachments {
  margin-bottom: 32px;
  flex-wrap: wrap;
  display: flex;
  gap: 12px;
}

.attachText1 {
  padding-bottom: 12px;
}

.attachText2 {
  padding-bottom: 32px;
}

.newTab {
  text-decoration: none;
}

.careGivenSection {
  display: flex;
  flex-direction: column;
  max-width: 472px;
}

.saveBtn {
  padding-top: 32px;
}
.spinner {
  height: 220px;
}

.postCallButtonGroup {
  margin-top: 32px;
  gap: 12px;
}

@media screen and (max-width: 1024px) {
  .noteBox {
    width: 380px;
    min-width: 380px;
  }
}
