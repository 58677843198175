.featuredIcon {
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  border-radius: var(--border-radius-pill);
}

.gray {
  background-color: var(--color-gray-50);
  color: var(--color-gray-900);
}

.primary {
  background-color: var(--color-primary-100);
  color: var(--color-primary-400);
}

.white {
  background-color: var(--color-white);
  color: var(--color-gray-900);
}

.transparent {
  color: var(--color-gray-900);
}

.success {
  background-color: var(--color-green-100);
  color: var(--color-green-600);
}

.danger {
  background-color: var(--color-red-100);
  color: var(--color-red-600);
}

.purple {
  background-color: var(--color-purple-100);
  color: var(--color-purple-600);
}

.blue {
  background-color: var(--color-blue-100);
  color: var(--color-blue-600);
}

.green {
  background-color: var(--cp-green-100);
  color: var(--cp-green-900);
}

.orange {
  background-color: var(--color-orange-100);
  color: var(--color-orange-600);
}

.lgOval {
  width: 70px;
  height: 44px;
  border-radius: 200px;
}

.xxl {
  width: 64px;
  height: 64px;
  /* font-size: 32px; */
}

.xl {
  width: 56px;
  height: 56px;
  /* font-size: 28px; */
}

.lg {
  width: 48px;
  height: 48px;
  /* font-size: 24px; */
}

.md {
  width: 40px;
  height: 40px;
  /* font-size: 20px; */
}

.sm {
  width: 32px;
  height: 32px;
  /* font-size: 16px; */
}

.xs {
  width: 24px;
  height: 24px;
  /* font-size: 12px; */
}
