.patientSearchContainer {
  display: flex;
  flex: 1 1;
  justify-content: center;
  align-items: center;
}
.dropdownSize {
  width: 393px;
}
.patientResultList {
  padding: 24px 80px;
  border-top: 1px solid var(--v2-color-dropdown-right);
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.patientResultList:last-child {
  border-bottom: 1px solid var(--v2-color-dropdown-right);
}
.patientResultListItem {
  flex: 0 0 auto;
}
.patientResultListItem1 {
  width: 35%;
}
.patientResultListItem2 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.patientResultListItem4 {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 20%;
}
.visitStyle {
  font-size: 12px;
  color: var(--rv-grey-text);
}
.visiteDateStyle,
.visitStyle {
  font-style: normal;
  font-weight: 400;
  font-family: ProximaNova;
}
.visiteDateStyle {
  font-size: 14px;
  color: var(--color-gray-900);
}
.patientResultWrapper {
  position: relative;
}
.patientResultSearch {
  padding: 64px 80px 40px 80px;
}
.headingGap {
  margin-bottom: 32px;
}
.searchBoxSize {
  width: 504px;
}
.dropdownStyle {
  width: 377px;
}
.patientResultListWrapper {
  min-height: 400px;
}
.noRecordFound {
  display: flex;
  justify-content: center;
}
.paginationContainer {
  display: flex;
  gap: 15px;
  padding: 40px 80px 40px 80px;
}

.names {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.link {
  font-weight: var(--font-weight-bold);
  line-height: var(--line-height-small);
  color: var(--color-primary-400);
  cursor: pointer;
  text-decoration: none;
  font-size: 18px;
}

.paginationWrapper {
  margin-bottom: 50px;
}

.loader {
  width: 65px;
  padding-top: 6px;
}

.visitTierWrapper {
  display: flex;
  width: 45%;
  justify-content: space-between;
}

@media screen and (max-width: 1024px) {
  .patientResultSearch {
    padding: 64px 30px 40px 30px;
  }
  .paginationContainer {
    padding: 40px 30px;
  }
  .patientResultList {
    padding: 24px 30px;
    justify-content: space-between;
  }
  .patientResultListItem1 {
    width: 45%;
  }
  .patientResultListItem3 {
    margin-top: 15px;
  }
  .link {
    width: 80%;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }
  .visitTierWrapper {
    flex-direction: column;
    align-items: flex-start;
    width: 30%;
  }
}

@media screen and (max-width: 991px) {
  .link {
    width: 70%;
  }
}
