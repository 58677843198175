.base {
  border-radius: var(--border-radius-small);
  padding: 16px;
  max-width: 330px;
  width: fit-content;
}

.sent {
  background-color: var(--color-primary-400);
}

.recieved {
  background-color: var(--color-gray-50);
  /* margin-left: 48px; */
}

.chatSender {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 16px;
}
