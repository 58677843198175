.note {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--color-gray-300);
  border-radius: var(--border-radius-small);
  padding: 24px;
}

.topRow {
  display: flex;
  justify-content: space-between;
}

.modifiedBy {
  display: flex;
  gap: 8px;
  margin-bottom: 8px;
  align-items: center;
}

.date {
  margin-bottom: 10px;
}

.title,
.description {
  margin-bottom: 16px;
  overflow-wrap: break-word;
}

.attachments {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  cursor: pointer;
}
