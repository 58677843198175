.content {
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  flex-grow: 1;
}

.searchAndFilter {
  display: flex;
  padding-top: 16px;
  padding-bottom: 32px;
  /* padding-left: 32px; */
}

.search {
  padding-right: 16px;
  /* width: 400px; */
}

.noMessages {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.heading {
  padding-top: 24px;
  padding-bottom: 12px;
}
