.notesList {
  padding: 24px 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.noNotes {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

.noNotesMessage {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}
