.resultDetail {
  overflow-y: auto;
  height: 100%;
}

.author {
  margin-top: 24px;
  margin-bottom: 8px;
}

.date {
  margin-top: 32px;
  margin-bottom: 8px;
}

.notes {
  margin-top: 32px;
  margin-bottom: 8px;
}

.orders {
  margin-top: 32px;
  margin-right: 32px;
  min-height: 150px;
}
