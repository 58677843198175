.weeklyHours {
  margin-bottom: 32px;
  border-right: 1px solid var(--color-separator-primary);
}

.weeklyHoursHeader {
  width: 506px;
  height: 28px;
  margin-bottom: 16px;
}

.weekdayAndHours {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.weekday {
  width: 180px;
  height: 24px;
  margin-top: 16px;
  margin-bottom: 16px;
}

.hours {
  width: 325px;
  height: 24px;
  margin-top: 16px;
  margin-bottom: 16px;
}

.additionalHours {
  margin-left: 180px;
  margin-bottom: 16px;
  width: 326px;
  height: 24px;
}

.editButton {
  margin-top: 32px;
}

.editWeekdayAndHours {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 473;
  height: 88;
  margin-right: 32px;
}

.cancelAndSaveButtons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.cancelButton {
  margin-top: 32px;
  margin-right: 12px;
}

.saveButton {
  margin-top: 32px;
  margin-right: 242px;
}
