.avatar {
  position: relative;
  color: var(--color-text-white);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  user-select: none;
  background-color: var(--color-primary-500);
  border-radius: var(--border-radius-pill);
}

.avatar img {
  border-radius: var(--border-radius-pill);
}

.online::after {
  content: "";
  width: calc(100% / 4);
  height: calc(100% / 4);
  border: 2px solid var(--color-white);
  border-radius: var(--border-radius-pill);
  background-color: var(--color-green-600);
  position: absolute;
  bottom: 0;
  right: 0;
}

.photo {
  background-color: transparent;
}

.initials {
  background-color: var(--color-primary-500);
}

.clickable {
  cursor: pointer;
}

.xxs {
  font-size: 10px;
  width: 20px;
  height: 20px;
}

.xs {
  font-size: 10px;
  width: 24px;
  height: 24px;
}

.sm {
  font-size: 14px;
  width: 32px;
  height: 32px;
}

.md {
  font-size: 16px;
  width: 40px;
  height: 40px;
}

.lg {
  font-size: 18px;
  width: 48px;
  height: 48px;
}

.xl {
  font-size: 20px;
  width: 56px;
  height: 56px;
}

.xxl {
  font-size: 28px;
  width: 64px;
  height: 64px;
}
