.userDetails {
  display: flex;
  flex-direction: column;
  flex: 1 1 1px;
  min-height: 0px;
  overflow: auto;
  align-items: center;
}

.avatar {
  margin-bottom: 16px;
  margin-top: 52px;
}

.name {
  margin-bottom: 4px;
}

.title {
  margin-bottom: 12px;
}

.patientInfo {
  text-transform: capitalize;
}

.bioHeading {
  margin-top: 40px;
  width: 100%;
}

.bio {
  margin-top: 16px;
  width: 100%;
}

.buttons {
  margin-top: 40px;
}
