.overridenDates {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 326px;
}

.date {
  display: flex;
  width: 128px;
  height: 32px;
  margin-right: 24px;
  align-items: center;
}

.hours {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.hoursAndDelete {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 13px;
}

.hourText {
  width: 117px;
  height: 21px;
}

.deleteButton {
  margin-left: 31px;
}
