.datePicker {
  margin-bottom: 16px;
  width: 326px;
}

.unavailable {
  margin-top: 16px;
}

.unavailableSwitch {
  margin-top: 24px;
}

.cancelAndAddButtons {
  margin-top: 32px;
}
