.select {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: auto;
  gap: 8px;
}

.label {
  display: block;
  font-size: 14px;
  line-height: var(--line-height-reset);
  font-weight: var(--font-weight-medium);
  letter-spacing: var(--font-tracking-none);
}

.valueContainer {
  box-sizing: border-box;
  display: flex;
  padding: 16px;
  /* align-items: stretch; */
  /* align-items: center; */
  /* justify-content: space-between; */
  gap: 8px;
  width: 150px;
  height: 133px;
  border: 1px solid var(--color-separator-primary);
  margin: 1px;
  border-radius: var(--border-radius-small);
  background-color: var(--color-white);
}

.valueContainer.focused {
  border-color: var(--color-primary-400);
}

.valueContainer.error {
  border-color: var(--color-red-600);
}

.valueContainer:hover:not(.focused, .disabled) {
  border-color: var(--color-gray-400);
}

.valueContainer.disabled {
  border-color: var(--color-gray-300);
}

.value {
  flex-grow: 1;
  min-width: 64px;
  padding: 0;
  margin: 0;
  font-family: ProximaNova;
  font-size: 16px;
  line-height: var(--line-height-medium);
  font-weight: var(--font-weight-regular);
  color: var(--color-text-primary);
  border: none;
  outline: none;
  resize: none;
  cursor: default;
  user-select: none;
  /* width: 198px; */
}

.value::placeholder {
  color: var(--color-text-secondary);
}

.value:disabled,
.value:disabled::placeholder {
  color: var(--color-text-disabled);
  background-color: white;
}

.icon {
  display: flex;
  flex: 1;
  align-items: center;
  max-width: 24px;
  color: var(--color-text-secondary);
}

.disabled .icon {
  color: var(--color-text-disabled);
}

.clearButton {
  display: flex;
  flex: 1;
  padding: 0;
  margin: 0;
  width: 20px;
  min-height: 24px;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  color: var(--color-text-secondary);
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
}

.hint {
  position: absolute;
  bottom: -29px;
  z-index: 20;
}

.selectedValue {
  display: flex;
  flex-direction: column;
}

.avatarAndValue {
  display: flex;
  flex-grow: 1;
}

.avatar {
  padding-right: 8px;
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.row {
  display: flex;
  align-items: center;
}

.today {
  width: 100px;
  height: 18px;
}

.dateToday {
  padding-bottom: 12px;
}

.dateNotToday {
  padding-bottom: 36px;
}

.disabled .today {
  color: var(--color-text-disabled);
}

.disabled .dateToday {
  color: var(--color-text-disabled);
}

.disabled .dateNotToday {
  color: var(--color-text-disabled);
}

.disabled .numberAvailable {
  color: var(--color-text-disabled);
}

.disabled .dayOfWeek {
  color: var(--color-text-disabled);
}
