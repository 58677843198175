.dateOverridesContent {
  margin-left: 32px;
  padding-bottom: 200px;
}

.dateOverridesHeading {
  width: 323px;
  height: 22px;
  margin-right: 32px;
}

.dateOverridesDescription {
  width: 323px;
  height: 42px;
  margin-top: 12px;
  margin-bottom: 24px;
}

.addButton {
  margin-bottom: 36px;
}
