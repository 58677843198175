.patientHeader {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  flex: 0 0;
  padding: 24px 32px 0 32px;
  border-bottom: 1px solid var(--color-gray-300);
}

.patientHeader.withoutBackBtn {
  flex-direction: row;
  justify-content: space-between;
  gap: 24px;
}

.leftContainer {
  flex-grow: 1;
}

.rightContainer {
  flex-grow: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 24px;
}

.buttonsContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.patientInfoContainer {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  margin-bottom: 32px;
}

.bottomContainer {
  display: flex;
  gap: 32px;
  justify-content: space-between;
}

.navContainer {
  flex-grow: 1;
  padding-top: 12px;
}

.careTeamContainer {
  display: flex;
  gap: 8px;
}

.newMessage {
  padding-left: 32px;
}

.boldSubText {
  font-weight: var(--font-weight-bold);
  line-height: var(--line-height-medium);
  color: var(--color-text-secondary);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 2px;
}
.drawerHeader {
  margin-bottom: 0px;
}
