.chatInput {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  border-top: 1px solid var(--color-gray-300);
  width: 100%;
  padding: 24px 16px;
  box-sizing: border-box;
  background-color: var(--color-background-primary);
}

.chatBottomBar {
  max-width: 600px;
}

.resourcesContainer {
  display: flex;
  min-width: 0px;
  width: 100%;
  overflow-x: auto;
}

.resourceCardContainer {
  display: flex;
  margin-right: 16px;
  box-sizing: border-box;
  transition: all 200ms ease-out;
}

.resourceCardContainer.exit {
  width: 180px;
  margin-right: 16px;
  opacity: 1;
  transform: translateY(0px);
}

.resourceCardContainer.exitActive {
  width: 0px;
  margin-right: 0px;
  opacity: 0;
  transform: translateY(100px);
}

.resourceCardContainer.enter {
  opacity: 0;
  transform: translateY(100px);
}

.resourceCardContainer.enterActive {
  opacity: 1;
  transform: translateY(0px);
}
