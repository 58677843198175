.searchSelect {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: auto;
  gap: 8px;
}

.label {
  display: block;
  font-size: 14px;
  line-height: var(--line-height-reset);
  font-weight: var(--font-weight-medium);
  letter-spacing: var(--font-tracking-none);
}

.inputContainer {
  position: relative;
  z-index: 20;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 11px 15px;
  gap: 8px;
  min-height: 48px;
  border: 1px solid var(--color-separator-primary);
  border-radius: var(--border-radius-small);
  background-color: var(--color-white);
}

.inputContainer.focused {
  border-color: var(--color-primary-400);
}

.inputContainer.error {
  border-color: var(--color-red-600);
}

.inputContainer:hover:not(.focused) {
  border-color: var(--color-gray-400);
}

.inputTextContainer {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  min-width: 0;
}

.inputTextContainer::-webkit-scrollbar {
  display: none;
}

.tagContainer {
  display: inline;
  width: fit-content;
  flex-shrink: 0;
}

.input {
  display: inline;
  flex-grow: 1;
  min-width: 64px;
  min-width: fit-content;
  padding: 0;
  margin: 0;
  font-family: ProximaNova;
  font-size: 16px;
  line-height: var(--line-height-medium);
  font-weight: var(--font-weight-regular);
  color: var(--color-text-primary);
  border: none;
  outline: none;
  resize: none;
}

.input::placeholder {
  color: var(--color-text-secondary);
}

.input:disabled,
.input:disabled::placeholder {
  color: var(--color-text-disabled);
  background-color: white;
}

.icon {
  display: flex;
  flex: 1;
  align-items: center;
  max-width: 24px;
  color: var(--color-text-secondary);
}

.clearButton {
  display: flex;
  flex: 1;
  padding: 0;
  width: 20px;
  min-height: 24px;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  color: var(--color-text-secondary);
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
}

.hint {
  position: absolute;
  bottom: -29px;
  z-index: 20;
}

.selectInput {
  cursor: default;
  user-select: none;
}
