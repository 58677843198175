.checkboxContainer {
  display: flex;
  gap: 8px;
  line-height: 24px;
  font-size: 14px;
  height: min-content;
  color: var(--color-text-primary);
  font-weight: var(--font-weight-medium);
  user-select: none;
}

.checkbox {
  color: var(--color-white);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 20px;
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid var(--color-gray-600);
  cursor: pointer;
}

.checkbox:hover:not(.disabled) {
  border-color: var(--color-gray-700);
}

.checkbox:focus {
  box-shadow: 0px 0px 0px 4px var(--color-primary-100);
}

.checkbox.disabled {
  cursor: default;
  color: var(--color-gray-300);
  border-color: var(--color-gray-300);
  background-color: var(--color-gray-50);
}

.checkbox.checked:not(.disabled) {
  background-color: var(--color-primary-400);
}

.checkbox.checked:hover:not(.disabled) {
  background-color: var(--color-primary-500);
}

.checkboxInput {
  display: none;
}
