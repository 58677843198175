.base {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--color-gray-300);
  box-sizing: border-box;
  border-radius: var(--border-radius-small);
  padding: 24px;
  width: 864px;
}
.base:first-child {
  margin-bottom: 24px;
}
.selectionContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 78px;
}
.heading {
  color: var(--color-gray-900);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 21.6px;
}
.steps {
  display: block;
  padding-top: 8px;
  color: var(--rv-grey-text);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.rightContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 45%;
}
.iconbg {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 28px;
  padding: 0px 12px;
  border-radius: 100px;
  background-color: var(--color-gray-50);
  margin-right: 8px;
}
.iconBlue {
  background-color: var(--color-primary-50);
  color: var(--color-primary-500);
}
.iconRed {
  background-color: var(--rv-red-50);
  color: var(--rv-red-700);
}
.iconGreen {
  background-color: var(--rv-green-50);
  color: var(--rv-green-700);
}
.valueContainer {
  max-width: 244px;
}
.alert,
.alertGreen {
  font-size: 13px;
  font-weight: 450;
  line-height: 18px;
}
.alertButtonContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.alertGreen {
  color: var(--rv-green-700);
}
