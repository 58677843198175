.content {
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  flex-grow: 1;
}

.noSubscription {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.heading {
  padding-top: 24px;
  padding-bottom: 50px;
}
