.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 112px;
  border-bottom: 1px solid var(--color-separator-primary);
}

.logoutButton {
  margin-top: 24px;
  margin-bottom: 52px;
  margin-right: 32px;
}

.avatarLabelGroup {
  margin-top: 24px;
  margin-bottom: 24px;
  margin-left: 32px;
}
