.providerSelectForm {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-grow: 1;
  height: 584px;
  min-height: 0px;
}

.listContaner {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 64px;
  overflow-y: auto;
  margin-right: 0;
  padding-right: 16px;
  margin-top: 24px;
}

.scrollbar::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px;
}

.scrollbar::-webkit-scrollbar-thumb {
  background-color: var(--color-gray-600);
  border-radius: 5px;
  min-height: 40px;
}

.scrollbar::-webkit-scrollbar-track {
  background-color: var(--color-gray-50);
}

.listLabel {
  background-color: white;
  z-index: 50;
  position: sticky;
  top: 0px;
}

.buttonContainer {
  margin-top: 12px;
  margin-bottom: 16px;
}

.participants {
  text-align: center;
}
