.addWeeklyHours {
  display: flex;
  flex-direction: column;
}

.editWeekdayAndHours {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 473;
  height: 88;
  margin-right: 32px;
}

.startHour {
  margin-top: 20px;
  margin-bottom: 20px;
  height: 48px;
}

.endHour {
  margin-top: 20px;
  margin-bottom: 20px;
  height: 48px;
}

.dash {
  width: 5px;
  height: 24px;
  margin-top: 32px;
  margin-bottom: 32px;
  margin-left: 12px;
  margin-right: 12px;
}

.button {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
}

.errorMsg {
  display: flex;
  justify-content: center;
  margin-bottom: 8px;
}
