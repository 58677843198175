.dropdown {
  position: relative;
  display: inline-block;
}

.dropdownToggle {
  padding: 10px 12px 10px 15px;
  cursor: pointer;
  background-repeat: no-repeat;
  border: 0;
  border-right: 1px solid var(--v2-color-dropdown-right);
  border-radius: var(--border-radius-small) 0 0px var(--border-radius-small);
  background-color: var(--color-white);
  width: 127px;
  background-position: calc(100% - 12px) center;
  text-align: left;
  color: var(--v2-color-text-dark);
  font-family: ProximaNova;
  font-size: 12px;
  font-weight: 500;
  height: 46px;
}

.dropdownMenu {
  position: absolute;
  top: calc(100% + 6px);
  left: 0;
  width: 250px;
  list-style: none;
  margin: 0;
  padding: 0;
  border-radius: var(--border-radius-small);
  box-shadow: 0px 5px 24px 0px var(--v2-shadow-lg);
  background-color: var(--color-white);
  border: 1px solid var(--v2-color-dropdown-right);
}

.dropdownMenu li {
  padding: 10px;
  cursor: pointer;
  font-family: ProximaNova;
  font-size: 14px;
  line-height: 100%;
  font-weight: 400;
  color: var(--v2-color-text-primary);
  padding: 13px 15px;
  display: flex;
  justify-content: space-between;
}
.dropdownMenu li + li {
  border-top: 1px solid var(--v2-color-dropdown-right);
}
.dropdownMenu li:hover {
  background-color: var(--v2-color-blue-hover);
}

.dropdownMenu li.selected {
  background-color: var(--v2-color-blue-hover);
}
