.filtersForm {
  width: 536px;
  height: 584px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;
}

.filtersContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 24px;
}

.providerSelect {
  padding-bottom: 24px;
}

.dateInputContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 12px;
  position: relative;
}

.dateInputSeparator {
  padding-bottom: 14px;
  user-select: none;
}
.dateErrorMsgContainer {
  margin-top: 10px;
}
