/* import font assets */

@font-face {
  font-family: "ProximaNova";
  src: url("../assets/fonts/ProximaNova/Regular.app.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "ProximaNova";
  src: url("../assets/fonts/ProximaNova/Medium.app.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "ProximaNova";
  src: url("../assets/fonts/ProximaNova/Bold.app.ttf") format("truetype");
  font-weight: 700;
}

/* import v2 font assets */
@font-face {
  font-family: "IvyPrestoHeadline";
  src: url("../assets/fonts/IvyPrestoHeadline/IvyPrestoHeadline-Light.ttf")
    format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "IvyPrestoHeadline";
  src: url("../assets/fonts/IvyPrestoHeadline/IvyPrestoSemiBold.ttf")
    format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "HelveticaNowText";
  src: url("../assets/fonts/HelveticaNowText/HelveticaNowText-Medium.otf")
    format("opentype");
  font-weight: 500;
}

@font-face {
  font-family: "HelveticaNowText";
  src: url("../assets/fonts/HelveticaNowText/HelveticaNowText-Regular.otf")
    format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: "HelveticaNowDisplay";
  src: url("../assets/fonts/HelveticaNowDisplay/HelveticaNowDisplay-Bd.otf")
    format("opentype");
  font-weight: 700;
}

@font-face {
  font-family: "HelveticaNowDisplay";
  src: url("../assets/fonts/HelveticaNowDisplay/HelveticaNowDisplay-Md.otf")
    format("opentype");
  font-weight: 500;
}

body {
  padding: 0;
  margin: 0;
  font-family: ProximaNova;
}

/* copy and use to clamp text wrap with ellipsis */
.clamped-wrap-w-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/* import style tokens */
@import url(./variables.css);
