.theme {
  border-radius: 50px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  font-family: ProximaNova;
  height: 24px;
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.theme.light {
  background-color: var(--color-gray-50);
  color: var(--color-gray-900);
}
.theme.dark {
  background-color: var(--color-gray-900);
  color: var(--color-white);
}
