.careTeamMemberContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 24px;
  padding-top: 24px;
  border-bottom: 1px solid var(--color-separator-primary);
}

.body {
  margin-left: 16px;
}
