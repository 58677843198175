.base {
  font-family: ProximaNova;
  font-weight: var(--font-weight-bold);
  line-height: var(--line-height-small);
  letter-spacing: var(--font-tracking-tight);
  padding: 0;
  margin: 0;
  color: var(--color-text-primary);
}

.heading1 {
  font-size: 28px;
}

.heading2 {
  font-size: 23px;
}
.heading3 {
  font-size: 18px;
}

.primary {
  color: var(--color-text-primary);
}

.secondary {
  color: var(--color-text-secondary);
}

.link {
  color: var(--color-text-link);
}

.disabled {
  color: var(--color-text-disabled);
}

.white {
  color: var(--color-text-white);
}

.danger {
  color: var(--color-red-600);
}

.inherit {
  color: inherit;
}
