.visitDetails {
  display: flex;
  padding-top: 24px;
  padding-bottom: 48px;
}

.search {
  padding-right: 16px;
}

@media screen and (max-width: 1024px) {
  .search {
    flex: 0 0 auto;
  }
}
