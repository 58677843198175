.base {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid var(--color-gray-300);
  box-sizing: border-box;
  border-radius: var(--border-radius-small);
  background-color: var(--color-white);
  padding: 24px;
  width: 864px;
  height: 168px;
}

.dateAndType {
  display: flex;
  padding-top: 36px;
  padding-bottom: 36px;
}

.heading {
  width: 184px;
  height: 22px;
}

.row {
  display: flex;
}

.apptDetails {
  margin-left: 48px;
  display: flex;
  flex-direction: column;
}

.tags {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.tag {
  margin-bottom: 8px;
}
