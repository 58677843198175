.avatarGroup {
  flex-shrink: 0;
  flex-grow: 0;
  position: relative;
}

.avatar {
  border-radius: var(--border-radius-pill);
}

/* avatars in xs container */

.xs .avatar1of2 {
  border: 1px solid var(--color-white);
}

.xs .avatar2of2 {
  margin-left: -6px;
  border: 1px solid var(--color-white);
}

.xs .avatar1of3 {
  border: 1px solid var(--color-white);
}

.xs .avatar2of3 {
  margin-left: -6px;
  border: 1px solid var(--color-white);
}

.xs .avatar3of3 {
  margin-left: -6px;
  border: 1px solid var(--color-white);
}

/* avatars in sm container */

.sm .avatar1of2 {
  border: 1px solid var(--color-white);
}

.sm .avatar2of2 {
  margin-left: -6px;
  border: 1px solid var(--color-white);
}

.sm .avatar1of3 {
  border: 1px solid var(--color-white);
}

.sm .avatar2of3 {
  margin-left: -6px;
  border: 1px solid var(--color-white);
}

.sm .avatar3of3 {
  margin-left: -6px;
  border: 1px solid var(--color-white);
}

/* avatars in md container */

.md .avatar1of2 {
  border: 1px solid var(--color-white);
}

.md .avatar2of2 {
  margin-left: -6px;
  border: 1px solid var(--color-white);
}

.md .avatar1of3 {
  border: 1px solid var(--color-white);
}

.md .avatar2of3 {
  margin-left: -6px;
  border: 1px solid var(--color-white);
}

.md .avatar3of3 {
  margin-left: -6px;
  border: 1px solid var(--color-white);
}

.center {
  display: flex;
  align-items: center;
}

.row {
  display: flex;
}
