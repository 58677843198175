.chatThreadContainer {
  /* overflow-y: auto; */
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-grow: 1;
  align-self: stretch;
}

.chatThread {
  max-width: 600px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: flex-start;
}

.sent {
  align-self: flex-end;
}

.recieved {
  margin-left: 48px;
  align-self: flex-start;
}

.timeStamp {
  text-align: center;
  margin-top: 16px;
  margin-bottom: 8px;
}

.spinnerContainer {
  position: relative;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.spinner {
  position: absolute;
  top: 64px;
  animation-name: spin;
  animation-duration: 700ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.buffer {
  height: 64px;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
