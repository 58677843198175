.apptList {
  display: flex;
  flex-direction: column;
  /* padding: 24px 32px 8px 32px; */
  min-height: 40px;
}

.emptyState {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 32px;
}

.heading {
  padding-top: 24px;
  padding-bottom: 12px;
}

.section {
  display: flex;
  background-color: var(--color-white);
  flex-shrink: 0;
  padding-bottom: 16px;
}

.noApptText {
  text-align: center;
  padding-bottom: 16px;
}

.dayTitle {
  background-color: #f5f5f4;
  position: sticky;
  top: 0;
  z-index: 10;
  border: 1px #d6d3d1;
  border-style: solid none;
}

.todayText {
  color: #1a6d93;
}

.notToday {
  color: #292524;
}

.apptCards {
  padding: 24px 32px 8px 32px;
  min-height: 40px;
}

.spinnerContainer {
  position: relative;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.spinner {
  position: absolute;
  top: 64px;
  animation-name: spin;
  animation-duration: 700ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
