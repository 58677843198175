.header {
  width: 864px;
  height: 28px;
  margin-bottom: 24px;
}

.borderBottom {
  /* border-bottom: 1px solid var(--color-separator-primary); */
  width: 100%;
}

.body {
  margin-bottom: 48px;
}

.spinner {
  height: 168px;
}
