/* MUI component styles are overridden using global css classes used by MUI components */

#tabs.MuiTabs-root {
  min-height: 40px;
  height: 40px;
  max-height: 40px;
  width: 100%;
  position: relative;
  justify-content: space-between;
}

#tabs .MuiTabs-indicator {
  background-color: var(--color-text-link);
}

#tabs .MuiTabs-flexContainer {
  gap: 24px;
  position: relative;
  width: 100%;
  left: 0;
}

#tabs .MuiTabs-scroller {
  position: absolute;
  width: 100%;
  z-index: 40;
}

#tabs .MuiTab-root {
  min-width: 32px;
  min-height: 40px;
  max-height: 40px;
  padding: 0 0 24px 0;
  text-transform: none;
  overflow: visible;
  font-family: ProximaNova;
  font-size: 16px;
  color: var(--color-text-secondary);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-reset);
  letter-spacing: var(--font-tracking-none);
}

#tabs .Mui-selected {
  color: transparent;
}

#tabs .Mui-selected::after {
  content: attr(aria-label);
  position: absolute;
  white-space: nowrap;
  width: fit-content;
  color: var(--color-text-link);
  font-weight: var(--font-weight-bold);
}

#tabs .MuiTabScrollButton-root {
  z-index: 50;
}

@media screen and (max-width: 1024px) {
  #tabs .MuiTabScrollButton-root {
    height: 50px;
  }
}
