.base {
  position: absolute;
  outline: 1px solid var(--color-white);
  width: 8px;
  height: 8px;
  border-radius: var(--border-radius-pill);
}

.alert {
  background-color: var(--color-red-600);
}

.neutral {
  background-color: var(--color-text-primary);
}
