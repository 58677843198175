.avatarDetail {
  display: flex;
  align-items: center;
  gap: 10px;
  img {
    border-radius: 50%;
    width: 28px;
    height: 28px;
    min-width: 28px;
    min-height: 28px;
    max-width: 28px;
    max-height: 28px;
    background-color: var(--v2-gray-200);
    object-fit: cover;
    display: block;
  }
}

.head,
.columnStyle {
  font-size: 14px;
  font-weight: 500;
  color: #766f6b;
  font-style: normal;
  line-height: 100%;
  letter-spacing: -0.07px;
  padding: 0 15px;
}

.rowStyle {
  display: flex;
  justify-content: flex-start;
  color: #292524;
  flex-grow: 1;
  padding: 0 15px;
  position: relative;
}

.column1 {
  width: 20%;
}

.column2 {
  width: 25%;
}

.column3 {
  width: 20%;
}

.column4 {
  width: 15%;
}

.column5 {
  width: 15%;
}

.column6 {
  width: 5%;
}

.left {
  justify-content: flex-start;
}

.center {
  justify-content: center;
}

.right {
  justify-content: flex-end;
}

.threeDots {
  cursor: pointer;
  border-radius: 50px;
  &:hover,
  &.active {
    background-color: #dddddd;
  }
}

.iconCircle {
  background-color: #efefef;
  width: 64px;
  height: 64px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.paginationContainer {
  display: flex;
  gap: 15px;
  padding-top: 25px;
  justify-content: center;
}

.avatarPlaceholder {
  border-radius: 50%;
  width: 28px;
  height: 28px;
  background-color: var(--color-primary-500);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #fff;
}
