.modalExit {
  width: 443px;
  position: fixed; /* Fixed position to center the modal relative to the viewport */
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%); /* Center the modal */
  background-color: white; /* Add background color if needed */
  z-index: 1000; /* Ensure the modal appears on top of other elements */
  /* Optional: Add padding, border, or box-shadow for better presentation */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.spinner {
  height: 168px;
}

.buttonDeactivate {
  color: var(--color-gray-300) !important;
  background-color: var(--color-gray-50) !important;
}

.attachmentButton {
  border: none;
}

.button {
  height: 40px;
}

.publishModal {
  width: 443px;
}

.publishModal,
.publishModal .contentContainer {
  overflow-y: visible;
}
