.modalContainer {
  position: fixed;
  top: 0px;
  display: flex;
  justify-content: center;
  height: 100%;
  max-height: 100%;
  width: 100%;
  background-color: rgba(41, 37, 36, 0.7);
  z-index: 9999;
  animation: fade-in 200ms linear;
}

.closing.modalContainer {
  animation: fade-out 200ms linear forwards;
}

.modal {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: var(--color-white);
  border-radius: var(--border-radius-medium);
  padding: 32px;
  margin-top: 48px;
  width: 600px;
  height: min-content;
  max-height: 90vh;
  animation: slide-up 200ms ease-out;
  overflow-y: auto;
}

.closing > .modal {
  animation: slide-down 200ms ease-in forwards;
}

.modalHeader {
  display: flex;
  flex-grow: 0;
  justify-content: space-between;
  gap: 16px;
  align-items: center;
  margin-bottom: 16px;
}

.headerTitle {
  display: flex;
  flex-direction: column;
  gap: 4px;
  /* text-align: center; */
}

.contentContainer {
  /* overflow-y: scroll; */
  position: relative;
  flex-grow: 1;
  /* display: flex;
    flex-direction: column; */
  min-height: 0px;
  /* overflow-y: auto; */
}

.page {
  flex-grow: 1;
  display: flex;
  width: 100%;
  flex-direction: column;
  min-height: 0px;
  transition: all 300ms ease-in-out;
  transform: translateX(0px);
}

.page.enter {
  transform: translateX(700px);
}

.page.enterActive {
  transform: translateX(0px);
}

.page.exit {
  position: absolute;
  transform: translateX(0px);
}

.page.exitActive {
  transform: translateX(700px);
}

.page.hide {
  position: absolute;
  transform: translateX(0px);
}

.page.hideActive {
  transform: translateX(-700px);
}

.page.hideDone {
  position: absolute;
  transform: translateX(-700px);
}

.page.appear {
  transform: translateX(-700px);
}

.page.appearActive {
  transform: translateX(0px);
}

.iconWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.icon {
  width: 20px;
  height: 20px;
}

.verticalCenter {
  align-items: center;
}

@keyframes fade-in {
  from {
    background-color: transparent;
  }
}

@keyframes fade-out {
  to {
    background-color: transparent;
  }
}

@keyframes slide-up {
  from {
    opacity: 0.01;
    margin-top: 96px;
  }

  to {
    opacity: 1;
    margin-top: 48px;
  }
}

@keyframes slide-down {
  from {
    opacity: 1;
    margin-top: 48px;
  }

  to {
    opacity: 1;
    margin-top: 48px;
    opacity: 0.01;
    margin-top: 96px;
  }
}
