.messagesPage {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  flex: 0 0 100%;
  height: 100%;
}

.chatListContainer {
  width: 388px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  border-right: 1px solid var(--color-separator-primary);
}

.chatContainer {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  overflow-y: auto;
  overflow-x: hidden;
}

.chatBottomBarContainer {
  display: flex;
  justify-content: center;
  border-top: 1px solid var(--color-gray-300);
  width: 100%;
  padding: 24px 16px;
  background-color: var(--color-background-primary);
}

.chatBottomBar {
  max-width: 500px;
}
