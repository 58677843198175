.select {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: auto;
  gap: 8px;
}

.label {
  display: block;
  font-size: 14px;
  line-height: var(--line-height-reset);
  font-weight: var(--font-weight-medium);
  letter-spacing: var(--font-tracking-none);
}

.valueContainer {
  box-sizing: border-box;
  display: flex;
  padding: 11px 15px;
  /* align-items: stretch; */
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  height: 48px;
  border: 1px solid var(--color-separator-primary);
  margin: 1px;
  border-radius: var(--border-radius-small);
  background-color: var(--color-white);
}

.valueContainer.focused {
  border-color: var(--color-primary-400);
}

.valueContainer.error {
  border-color: var(--color-red-600);
}

.valueContainer:hover:not(.focused, .disabled, .error) {
  border-color: var(--color-gray-400);
}

.valueContainer.disabled {
  border-color: var(--color-gray-300);
}

.value {
  flex-grow: 1;
  min-width: 64px;
  width: 100%;
  padding: 0;
  margin: 0;
  font-family: ProximaNova;
  font-size: 16px;
  line-height: var(--line-height-medium);
  font-weight: var(--font-weight-regular);
  color: var(--color-text-primary);
  border: none;
  outline: none;
  resize: none;
  cursor: default;
  user-select: none;
  /* width: 198px; */
}

.directionRight{
  background-color: var(--color-button-secondary);
  width: 90%;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  padding: 0px 12px 0px;
  letter-spacing: -0.06px;
}

.border{
  border: 1px solid var(--v2-color-border);
}

.value::placeholder {
  color: var(--color-text-secondary);
}

.value:disabled,
.value:disabled::placeholder {
  color: var(--color-text-disabled);
  background-color: white;
}

.icon {
  display: flex;
  flex: 1;
  align-items: center;
  max-width: 24px;
  color: var(--color-text-secondary);
}

.disabled .icon {
  color: var(--color-text-disabled);
}

.clearButton {
  display: flex;
  flex: 1;
  padding: 0;
  margin: 0;
  width: 20px;
  min-height: 24px;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  color: var(--color-text-secondary);
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
}

.hint {
  position: absolute;
  bottom: -29px;
  z-index: 20;
}

.selectedValue {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.avatarAndValue {
  display: flex;
  flex-grow: 1;
}

.avatar {
  padding-right: 8px;
}
