.tag {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: 4px;
  flex-grow: 0;
  width: fit-content;
  padding-left: 12px;
  padding-right: 12px;
  font-family: ProximaNova;
  border-radius: var(--border-radius-pill);
  box-sizing: border-box;
  user-select: none;
}

.tag button {
  color: inherit;
  height: inherit;
  display: flex;
  align-items: center;
  padding: 0;
  background-color: transparent;
  outline: none;
  border: none;
}

.tagInner {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.clickable {
  cursor: pointer;
}

.md {
  height: 24px;
}

.lg {
  height: 28px;
}

.neutral {
  color: var(--color-text-primary);
}

.info {
  color: var(--color-text-link);
}

.success {
  color: var(--color-green-700);
}

.danger {
  color: var(--color-red-700);
}

.black {
  color: var(--color-white);
}

.tag.neutral {
  background-color: var(--color-gray-50);
}

.tag.info {
  background-color: var(--color-primary-50);
}

.tag.success {
  background-color: var(--color-green-50);
}

.tag.danger {
  background-color: var(--color-red-50);
}

.tag.black {
  background-color: var(--color-gray-900);
}

.loading {
  opacity: 0;
}

.spinner {
  position: absolute;
  animation-name: spin;
  animation-duration: 700ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
