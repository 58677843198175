.vitalCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid var(--color-gray-300);
  box-sizing: border-box;
  border-radius: var(--border-radius-small);
  background-color: var(--color-white);
  padding: 16px;
  width: 130px;
}

.data {
  padding-top: 12px;
}

.label {
  padding-bottom: 4px;
  text-align: center;
}
