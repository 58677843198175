.formWrapper {
  padding-top: 30px;
  padding-bottom: 30px;
}

.fieldsWrapper {
  padding-top: 20px;
  display: flex;
  flex-wrap: wrap;
  max-width: 900px;
  gap: 20px;
}

.inputField {
  max-width: 400px;
}

.buttonWrapper {
  padding-top: 30px;
}

.generatedCodeDisplay {
  letter-spacing: 4px;
  width: fit-content;
  margin-top: 30px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  padding: 15px;
  color: dodgerblue;
  font-size: 30px;
  font-weight: bold;
}

.generatedModalReminderWrapper {
  padding-top: 15px;
  padding-bottom: 15px;
}

.generatedModalInfoWrapper {
  display: flex;
  gap: 25px;
}

.generatedModalInfoItemLabel {
  font-weight: bold;
}
