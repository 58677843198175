.dropdown {
  border-radius: var(--border-radius-small);
  box-shadow: var(--shadow-lg);
  background-color: var(--color-white);
  position: absolute;
  top: calc(100% + 8px);
  width: 100%;
  /* width: 400px; */
  overflow-y: auto;
  z-index: 9999;
}

.directionRight{
  right: 24px;
  width: 297px;
  top: calc(100% + 0px);
  min-width: 297px;
}

.dropdown::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px;
}

.dropdown::-webkit-scrollbar-thumb {
  background-color: var(--color-gray-600);
  border-radius: 5px;
  min-height: 40px;
}

.dropdown::-webkit-scrollbar-track {
  background-color: var(--color-gray-50);
}

.dropdownOption {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  padding: 0 16px;
  height: 44px;
  /* height: 56px; */
  background-color: var(--color-white);
  cursor: pointer;
}

.dropdownOption.selected {
  background-color: var(--color-primary-25);
}

.dropdownOption:hover {
  background-color: var(--color-primary-50);
  color: var(--color-text-link);
}

.dropdownOption:hover > .dropdownOptionName {
  color: var(--color-text-link);
}

.dropdownOptionNameAndSecondaryText {
  display: flex;
  flex-direction: column;
  user-select: none;
  flex-grow: 1;
}

.dropdownOptionName {
  display: flex;
  align-items: center;
}

.premium {
  padding-left: 4px;
}


.dropdownOptionCheckbox {
  display: none;
  padding-right: 10px;
}

.dropdownOptionCheckbox:checked + .dropdownOptionCheckmark {
  visibility: visible;
  padding-right: 10px;
}

.dropdownOptionCheckmark {
  visibility: hidden;
  color: var(--color-text-link);
  padding-right: 10px;
}

.noResults {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 0 16px;
  height: 44px;
  background-color: var(--color-white);
}
