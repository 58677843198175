.accordion {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.titleBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  outline: none;
  border: none;
  padding: 12px 16px;
  gap: 8px;
  margin: 0;
  background-color: transparent;
}

.title {
  flex-grow: 1;
  text-align: start;
}

.contentContainer {
  overflow: hidden;
  padding: 0px 16px;
  background-color: var(--color-background-secondary);
  border-bottom: 1px solid var(--color-gray-300);
}

.contentContainer.expanded {
  padding: 16px;
  overflow: visible;
}

.content {
  padding: 1px;
}

.accordionGroup {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 0px;
}
