.sectionInfoEmpty {
  display: flex;
  flex-direction: column;
  background-color: var(--color-white);
  flex-shrink: 0;
  padding: 48px 32px 0px 32px;
}

.sectionInfoPresent {
  display: flex;
  flex-direction: column;
  background-color: var(--color-white);
  flex-shrink: 0;
  padding: 64px 32px 0px 32px;
}

.patientInfoEmpty {
  border-bottom: 1px solid var(--color-separator-primary);
  width: 100%;
}

.header {
  width: 864px;
  height: 28px;
  margin-bottom: 24px;
}

.sectionInfo {
  display: flex;
  flex-direction: row;
  margin-bottom: 48px;
}

.row {
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
}

.rowNoMarginBottom {
  display: flex;
  flex-direction: row;
}

.lastRow {
  display: flex;
  flex-direction: row;
  margin-bottom: 48px;
}

.column1 {
  display: flex;
  flex-direction: column;
  margin-right: 24px;
}

.column2 {
  display: flex;
  flex-direction: column;
  margin-right: 24px;
}

.category {
  width: 140px;
  height: 24px;
  margin-right: 24px;
}

.info {
  width: 256px;
}

.address {
  display: flex;
  flex-direction: column;
}

.insurance {
  margin-top: 32px;
}
