.tabs {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  padding-left: 32px;
  align-items: stretch;
  gap: 24px;
  height: 69px;
  background-color: var(--color-gray-25);
  overflow-x: auto;
  max-width: 100%;
}

.tabContainer {
  position: relative;
}

.tab {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: default;
  white-space: nowrap;
  color: var(--color-text-secondary);
  cursor: pointer;
}

.active {
  color: transparent;
}

.active::after {
  content: attr(aria-label);
  position: absolute;
  white-space: nowrap;
  width: fit-content;
  left: auto;
  color: var(--color-text-link);
  font-weight: var(--font-weight-bold);
}
