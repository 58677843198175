.textInput {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
}

.label {
  display: block;
  font-size: 14px;
  line-height: var(--line-height-reset);
  font-weight: var(--font-weight-medium);
  letter-spacing: var(--font-tracking-none);
  color: var(--color-input-label);
}

.inputContainer {
  position: relative;
  box-sizing: border-box;
  display: flex;
  width: 100%;
  padding: 11px 15px;
  align-items: stretch;
  gap: 8px;
  min-height: 48px;
  height: min-content;
  border: 1px solid var(--color-separator-primary);
  border-radius: var(--border-radius-small);
  background-color: var(--color-white);
}

.inputContainer.focused {
  border-color: var(--color-primary-400);
}

.inputContainer.error {
  border-color: var(--color-red-600);
}

.inputContainer:hover:not(.focused, .error) {
  border-color: var(--color-gray-400);
}

.inputTextContainer {
  display: flex;
  gap: 8px;
  width: 100%;
  height: min-content;
}

.input {
  width: 100%;
  padding: 11px 0px;
  margin: -11px 0px;
  background-color: transparent;
  font-family: ProximaNova;
  font-size: 16px;
  line-height: var(--line-height-medium);
  font-weight: var(--font-weight-regular);
  color: var(--color-text-primary);
  overflow-y: auto;
  border: none;
  outline: none;
  overflow: hidden;
}

.multiline {
  resize: none;
  overflow-y: auto;
}

.datePicker {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 40px;
  opacity: 1;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  min-width: 0px;
  opacity: 0;
}

.datePicker::-webkit-inner-spin-button,
.datePicker::-webkit-calendar-picker-indicator {
  position: absolute;
  right: 0px;
  height: 100%;
  width: 40px;
}

.input::placeholder {
  color: var(--color-text-secondary);
}

.input:disabled,
.input:disabled::placeholder {
  color: var(--color-text-disabled);
}

.icon {
  display: flex;
  flex: 1;
  align-items: center;
  max-width: 24px;
  color: var(--color-text-secondary);
}

.clearButton {
  display: flex;
  flex: 1;
  padding: 0;
  margin: 0;
  width: 20px;
  min-height: 24px;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  color: var(--color-text-secondary);
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
}

.hint {
  position: absolute;
  bottom: -29px;
  z-index: 20;
}
