.resourcesSelectionContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.resourcesSelectionHeader {
  border-bottom: 1px solid var(--color-separator-primary);
}

.searchSortContainer {
  display: flex;
  gap: 16px;
  margin-bottom: 32px;
}

.resourcesSelectionList {
  overflow-y: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  gap: 24px;
  row-gap: 24px;
}

.interactiveCard {
  grid-row: 1fr;
}

.bufferSpace {
  height: 0px;
  grid-column: span 2;
}

.noResults {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.noResultsIcon {
  padding-top: 32px;
  padding-bottom: 24px;
}
