.editWeekdayAndHours {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-right: 32px;
  box-sizing: border-box;
  border-bottom: 1px solid var(--color-separator-primary);
}

.weeklyHoursHeader {
  width: 506px;
  height: 28px;
  margin-bottom: 16px;
}

.checkbox {
  width: 98px;
  margin-top: 34px;
}

.unavailable {
  margin-top: 34px;
  margin-bottom: 30px;
}

.button {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
  margin-top: 20px;
}

.additionalHrs {
  margin-left: 98px;
}

.disabled {
  background-color: transparent;
}

.errorMsg {
  display: flex;
  justify-content: center;
  margin-bottom: 8px;
}

.cancelAndSaveButtons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.cancelButton {
  margin-top: 32px;
  margin-right: 12px;
}

.saveButton {
  margin-top: 32px;
  margin-right: 242px;
}
