.header {
  display: flex;
  flex-direction: column;
  box-shadow: 0px 1px 0px var(--color-gray-300);
  padding: 24px 32px 0px 32px;
}

.searchAndFilter {
  display: flex;
  padding-top: 16px;
  /* padding-bottom: 24px; */
}

.search {
  padding-right: 16px;
}

.navContainer {
  flex-grow: 1;
  padding-top: 32px;
}

.emptyState {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 32px;
}

.noNotifs {
  padding-top: 24px;
  padding-bottom: 12px;
}

.notifs {
  padding: 32px 32px 32px 32px;
}

.notif {
  padding-bottom: 16px;
}
