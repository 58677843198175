.base {
  user-select: none;
  min-width: 0;
  position: relative;
  height: 104px;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  gap: 16px;
  padding: 8px 16px 8px 32px;
  box-sizing: border-box;
  cursor: pointer;
}

.base:hover:not(.selected) {
  background-color: var(--color-gray-25);
}

.base:active {
  background-color: var(--color-gray-50);
}

.avatarContainer {
  width: 48px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.chatPreviewContainer {
  min-width: 0;
  flex-grow: 1;
}

.nameTimeContainer {
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: space-between;
  align-items: center;
}

.nameContainer {
  min-width: 0;
  gap: 4px;
  display: flex;
  flex-direction: row;
}

.names {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.time {
  white-space: nowrap;
  width: min-content;
  text-align: end;
}

.subText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.previewContainer {
  padding-top: 4px;
  padding-right: 16px;
}

.preview {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  overflow-wrap: break-word;
}

.preview.truncate {
  -webkit-line-clamp: 1;
}

.preview.italic {
  font-style: italic;
}

.selected {
  background-color: var(--color-gray-50);
}

@media screen and (max-width: 1024px) {
  .base {
    padding: 8px 16px 8px 20px;
  }
}
