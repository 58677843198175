.patientSelectForm {
  height: 584px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.searchAndListContainer {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-grow: 1;
  min-height: 0px;
  height: 460px;
}

.listContaner {
  display: flex;
  flex-direction: column;
  min-height: 64px;
  overflow-y: auto;
  margin-right: 0;
  padding-right: 16px;
  margin-top: 24px;
  max-height: 390px;
}

.scrollbar::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px;
}

.scrollbar::-webkit-scrollbar-thumb {
  background-color: var(--color-gray-600);
  border-radius: 5px;
  min-height: 40px;
}

.scrollbar::-webkit-scrollbar-track {
  background-color: var(--color-gray-50);
}

.listLabel {
  background-color: white;
  z-index: 50;
  position: sticky;
  top: 0px;
}

.providersOnlyOption {
  padding: 12px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--color-separator-primary);
}

.noResult {
  text-align: center;
  margin-top: 50px;
}

.spinnerWrapper {
  height: 30px;
  margin-top: 10px;
}
