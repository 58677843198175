.resourceDetails {
  overflow-y: auto;
  height: 100%;
  margin-right: -32px;
  padding-right: 32px;
}

.bannerContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--border-radius-small);
  overflow: hidden;
  height: 394px;
}

.playIcon {
  position: absolute;
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.caption {
  margin-top: 12px;
  margin-bottom: 24px;
}

.caption > a {
  text-decoration: none;
  color: inherit;
}

.bannerContainer > img {
  object-fit: cover;
}

.videoThumbnail {
  background-color: var(--color-text-disabled);
}

.type {
  margin-top: 32px;
  text-transform: capitalize;
}

.title,
.author {
  margin-top: 8px;
}

.tagContainer {
  display: flex;
  gap: 8px;
  margin-top: 12px;
  margin-bottom: 24px;
}

.section {
  margin-bottom: 24px;
}

.emptySpace {
  height: 64px;
}

.image {
  object-fit: contain;
  width: 100%;
  border-radius: var(--border-radius-small);
  overflow: hidden;
}
