.notificationContent {
  width: 800px;
  max-height: 78px;
  border-radius: 8px 8px 0px 0px;
  padding: 12px;
  display: flex;
  justify-content: space-between;
}

.iconAndText {
  display: flex;
}

.grayLight {
  background-color: var(--color-gray-25);
}

.dangerLight {
  background-color: var(--color-red-25);
}

.purpleLight {
  background-color: var(--color-purple-25);
}

.primaryLight {
  background-color: var(--color-primary-25);
}

.successLight {
  background-color: var(--color-green-25);
}

.grayDark {
  background-color: var(--color-gray-50);
}

.dangerDark {
  background-color: var(--color-red-50);
}

.purpleDark {
  background-color: var(--color-purple-50);
}

.primaryDark {
  background-color: var(--color-primary-50);
}

.successDark {
  background-color: var(--color-green-50);
}

.text {
  display: flex;
  flex-direction: column;
  padding-left: 12px;
}

.notificationTimestamp {
  width: 800px;
  max-height: 78px;
  border-radius: 0px 0px 8px 8px;
  padding: 12px;
  display: flex;
  justify-content: space-between;
}

.avatar {
  padding-right: 4px;
}

.userAndTime {
  display: flex;
  align-items: center;
}

.preview {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@media screen and (max-width: 1024px) {
  .notificationContent,
  .notificationTimestamp {
    width: auto;
  }
}
