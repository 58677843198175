.header {
  display: flex;
  flex-direction: column;
  box-shadow: 0px 1px 0px var(--color-gray-300);
  padding: 24px 32px 24px 32px;
}

.page {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 0px;
}

.searchAndFilterAndAppt {
  display: flex;
  justify-content: space-between;
  padding-top: 16px;
}

.searchAndFilter {
  display: flex;
  gap: 15px;
}

.dateButtons {
  display: flex;
  flex-direction: row;
  justify-content: left;
  padding-top: 32px;
}

.dateHeading {
  width: 200px;
}

.listContainer {
  position: relative;
  min-height: 0px;
  flex: 1 1 1px;
  overflow-y: auto;
}

.drawer {
  width: 100%;
  overflow-y: auto;
}

.input {
  width: 400px;
}

@media screen and (max-width: 1024px) {
  .search {
    width: 300px !important;
  }
}

@media screen and (max-width: 991px) {
  .search {
    width: 280px !important;
  }
  .input {
    width: 275px;
  }
}
