.avatarGroup {
  flex-shrink: 0;
  flex-grow: 0;
  position: relative;
  border-radius: var(--border-radius-pill);
  background-color: var(--color-gray-50);
}

.lg {
  width: 48px;
  height: 48px;
}

.xl {
  width: 56px;
  height: 56px;
}

.avatar {
  position: absolute;
  border-radius: var(--border-radius-pill);
}

/* avatars in xl container */

.xl .avatar1of2 {
  top: 6px;
  left: 8px;
}

.xl .avatar2of2 {
  bottom: 5px;
  right: 8px;
  border: 1px solid var(--color-gray-50);
}

.xl .avatar1of3 {
  top: 5px;
  left: 18px;
}

.xl .avatar2of3 {
  left: 7px;
  bottom: 11px;
}

.xl .avatar3of3 {
  right: 7px;
  bottom: 11px;
}

/* avatars in lg container */

.lg .avatar1of2 {
  top: 4px;
  left: 5px;
  border: 1px solid var(--color-gray-50);
}

.lg .avatar2of2 {
  bottom: 4px;
  right: 4px;
  border: 1px solid var(--color-gray-50);
}

.lg .avatar1of3 {
  top: 2px;
  left: 16px;
}

.lg .avatar2of3 {
  left: 3px;
  bottom: 9px;
}

.lg .avatar3of3 {
  bottom: 6px;
  right: 4px;
}
