.base {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid var(--color-gray-300);
  box-sizing: border-box;
  border-radius: var(--border-radius-small);
  background-color: var(--color-white);
  padding: 24px;
  width: 600px;
  height: 160px;
}

.row {
  display: flex;
}

.tags {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.tag {
  padding-bottom: 12px;
}

.modal {
  height: 700px;
}
