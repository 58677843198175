.avatarLabelGroup {
  flex-shrink: 1;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  max-width: 100%;
  min-width: 0px;
}

.infoContainer {
  display: flex;
  min-width: 0;
  flex-direction: column;
}

.nameContainer {
  display: flex;
  justify-content: flex-start;
  gap: 4px;
  font-weight: var(--font-weight-bold);
  line-height: var(--line-height-small);
}

.names {
  color: var(--color-text-primary);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.link {
  color: var(--color-primary-400);
  cursor: pointer;
}

.newTab {
  text-decoration: none;
  color: var(--color-primary-400);
}

.subText {
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-medium);
  color: var(--color-text-secondary);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sm .names {
  font-size: 14px;
}

.md .names {
  font-size: 16px;
}

.lg .names {
  font-size: 18px;
}

.xl .names {
  font-size: 18px;
}

.xxl .names {
  font-size: 18px;
}

.sm .subText {
  font-size: 12px;
}

.md .subText {
  font-size: 12px;
}

.lg .subText {
  font-size: 14px;
}

.xl .subText {
  font-size: 14px;
}

.xxl .subText {
  font-size: 14px;
}
