.selectContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 16px;
  margin-right: 32px;
  box-sizing: border-box;
}

.startHour {
  margin-right: 12px;
  width: 125px;
  height: 48px;
}

.endHour {
  margin-left: 12px;
  margin-right: 12px;
  width: 125px;
  height: 48px;
}

.errorMsg {
  margin-top: 8px;
}
