.table {
  border: 1px solid var(--color-separator-primary);
  border-radius: var(--border-radius-small);
  overflow: hidden;
  position: relative;
}

.table.fullWidth {
  width: 100%;
}

.header {
  padding: 16px;
  border-bottom: 1px solid var(--color-separator-primary);
}

.labelRow {
  box-shadow: 0px 0.5px 0px 0px var(--color-separator-primary);
  background-color: var(--color-background-secondary);
  empty-cells: show;
  position: sticky;
  top: 0;
}

.label {
  text-align: left;
  padding: 10px 16px;
  font-size: var(--font-size-body-sm);
  font-weight: var(--font-weight-bold);
  color: var(--color-gray-900);
}

.tableContent.expanded {
  max-height: 690px;
  overflow-y: scroll;
}

.tableContent > table {
  table-layout: fixed;
  empty-cells: show;
  border-collapse: collapse;
  background-color: var(--color-background-secondary);
  border-width: 0px;
  width: 100%;
  empty-cells: show;
}

.tableContent > table > th {
  background-color: var(--color-background-secondary);
}

.actionContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: var(--color-text-link);
}

.linkContainer {
  display: flex;
  align-items: center;
  color: var(--color-text-link);
}

.row {
  background-color: var(--color-background-primary);
  /* box-sizing: border-box; */
  border-top: 1px solid var(--color-separator-primary);
}

.cell {
  padding: 24px 16px;
  max-width: 270px;
  font-size: var(--font-size-body-sm);
  font-weight: var(--font-weight-regular);
  color: var(--color-gray-900);
}

.noDataMessage {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-background-primary);
  padding: 16px;
}

.footer {
  border-top: 1px solid var(--color-separator-primary);
  padding: 16px;
}
