.switchContainer {
  display: flex;
  gap: 8px;
  line-height: 24px;
  font-size: 14px;
  color: var(--color-text-primary);
  font-weight: var(--font-weight-medium);
  user-select: none;
}

.switch {
  /* transition: background-color linear 100ms; */
  background-color: var(--color-gray-600);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 40px;
  padding: 0px;
  margin: 0px;
  cursor: pointer;
  border-radius: var(--border-radius-pill);
}

.switch.active:not(.disabled) {
  background-color: var(--color-primary-400);
}

.switch:hover:not(.disabled) {
  border-color: var(--color-gray-700);
}

.switch.active:hover:not(.disabled) {
  background-color: var(--color-primary-500);
}

.switch:focus {
  box-shadow: 0px 0px 0px 4px var(--color-primary-100);
}

.switch.disabled {
  cursor: default;
  background-color: var(--color-gray-50);
}

.switch.disabled.active {
  background-color: var(--color-gray-300);
}

.switch.disabled > .switchIndicator {
  background-color: var(--color-gray-300);
}

.switch.disabled.active > .switchIndicator {
  background-color: var(--color-gray-50);
}

.switchIndicator {
  transition: left ease-in-out 100ms;
  position: absolute;
  left: 2px;
  height: 20px;
  width: 20px;
  background-color: var(--color-white);
  border-radius: var(--border-radius-pill);
}

.switch.active > .switchIndicator {
  left: 18px;
}

.switchInput {
  display: none;
}
