.alertBanner {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 100%;
  height: 36px;
  padding: 8px;
  box-sizing: border-box;
  text-align: center;
  color: var(--color-white);
  overflow: hidden;
  min-height: 0px;
  animation: expand 200ms ease-in-out;
}

.neutral {
  background-color: var(--color-gray-600);
}

.info {
  background-color: var(--color-primary-400);
}

.success {
  background-color: var(--color-green-700);
}

.danger {
  background-color: var(--color-red-700);
}

.warning {
  background-color: var(--color-yellow-700);
}

.closeButton {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 100%;
  padding: 8px;
  position: absolute;
  right: 0px;
  outline: none;
  border: none;
  margin: 0;
  background-color: transparent;
}

@keyframes expand {
  from {
    height: 0;
    padding: 0;
  }
}
