.noteEditForm {
  display: flex;
  gap: 64px;
  align-items: stretch;
}

.leftColumn {
  flex: 0 1 auto;
  min-width: 1px;
  display: flex;
  flex-direction: column;
  height: min-content;
}

.rightColumn {
  flex: 0 0 300px;
  overflow: hidden;
  height: 100%;
  padding-bottom: 32px;
  box-sizing: border-box;
}

.title {
  margin-bottom: 24px;
}

.titleLabel {
  margin-bottom: 16px;
}

.descriptionLabel {
  margin-bottom: 16px;
}

.descriptionInput {
  margin-bottom: 40px;
}

.attachmentsLabel {
  margin-bottom: 12px;
}

.attachments {
  margin-bottom: 32px;
  flex-wrap: wrap;
  display: flex;
  gap: 12px;
  cursor: pointer;
}

.attachmentsSubtext1 {
  margin-bottom: 12px;
}

.attachmentsSubtext2 {
  margin-bottom: 32px;
}

.separator {
  height: 1px;
  margin: 32px 0px;
  background-color: var(--color-separator-primary);
}

.authorLogs {
  display: flex;
  flex-direction: column;
  background-color: var(--color-gray-50);
  border-radius: var(--border-radius-small);
  padding: 24px;
  overflow-y: auto;
  height: 100%;
  box-sizing: border-box;
  gap: 12px;
}

.logs {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.log {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.logAuthor {
  display: flex;
  align-items: center;
  gap: 8px;
}

.authorName {
  font-weight: 400;
}

.newTab {
  text-decoration: none;
}
