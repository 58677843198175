/**
 * Do not edit directly
 * Generated on Fri, 12 Nov 2021 17:26:29 GMT
 */

:root {
  --spacing-10: 4rem;
  --spacing-9: 3rem;
  --spacing-8: 2.5rem;
  --spacing-7: 2rem;
  --spacing-6: 1.5rem;
  --spacing-5: 1.25rem;
  --spacing-4: 1rem;
  --spacing-3: 0.75rem;
  --spacing-2: 0.5rem;
  --spacing-1: 0.25rem;
  --opacity-90: 90%;
  --opacity-80: 80%;
  --opacity-70: 70%;
  --opacity-60: 60%;
  --opacity-50: 50%;
  --opacity-40: 40%;
  --opacity-30: 30%;
  --opacity-20: 20%;
  --opacity-10: 10%;
  --line-height-medium: 150%;
  --line-height-small: 120%;
  --line-height-reset: 100%;
  --font-tracking-tight: -0.15px;
  --font-tracking-none: 0px;
  --font-weight-bold: 700;
  --font-weight-medium: 600;
  --font-weight-regular: 500;
  --font-size-body-xs: 12px;
  --font-size-body-sm: 14px;
  --font-size-body-md: 16px;
  --font-size-heading-03: 18px;
  --font-size-heading-02: 23px;
  --font-size-heading-01: 28px;
  --font-family-primary: proximanova;
  --color-shadow-xl: rgba(41, 27, 26, 0.15);
  --color-shadow-lg: rgba(41, 27, 26, 0.1);
  --color-shadow-md: rgba(41, 27, 26, 0.1);
  --color-purple-700: #5925dc; /* text */
  --color-purple-600: #6938ef; /* icon color */
  --color-purple-100: #ebe9fe; /* background */
  --color-purple-50: #f4f3ff; /* background */
  --color-purple-25: #fafaff; /* background */
  --color-blue-600: #4993d0; /* icon color */
  --color-blue-100: #d3e7fd; /* background */
  --color-green-700: #027a48; /* text */
  --color-green-600: #039855; /* icon color */
  --color-green-100: #d1fadf; /* background */
  --color-green-50: #ecfdf3; /* background */
  --color-green-25: #f6fef9; /* background */

  --color-yellow-650: #775d4e; /* text */
  --color-yellow-700: #b54708; /* text */
  --color-yellow-600: #dc6803; /* icon color */
  --color-yellow-100: #fef0c7; /* background */
  --color-yellow-30: #fef0c8; /* background */
  --color-yellow-border: #e4d7ba; /* background */
  --color-yellow-50: #fffaeb; /* background */
  --color-yellow-25: #fffcf5; /* background */

  --color-orange-600: #ce5c36; /* icon color */
  --color-orange-100: #fbebdb; /* background */
  --color-red-700: #b42318; /* text */
  --color-red-600: #d92d20; /* icon color */
  --color-red-100: #fee4e2; /* background */
  --color-red-50: #fef3f2; /* background */
  --color-red-25: #fffbfa; /* background */
  --color-white: #ffffff; /* primary surface */
  --color-gray-900: #292524; /* primary text */
  --color-gray-700: #514c48; /* Hover state for inputs */
  --color-gray-600: #766f6a; /* seconday text */
  --color-gray-400: #b7b2ae;
  --color-gray-300: #d6d3d1; /* border/disabled text */
  --color-gray-50: #f5f5f4; /* secondary surface */
  --color-gray-25: #fafafa;
  --color-black: #000000; /* pure black */
  --cp-green-100: #e7ebe5; /* care plan background */
  --cp-green-900: #454d42; /* care plan icon color */
  --color-primary-900: #001925; /* background color */
  --color-primary-700: #1a6d93;
  --color-primary-600: #003249; /* primary button pressed */
  --color-primary-500: #003f5c; /* primary button */
  --color-primary-400: #1a6e95; /* link */
  --color-primary-200: #bedee9;
  --color-primary-100: #ddedf4;
  --color-primary-50: #ecf5f9;
  --color-primary-25: #f6fbfd;
  --rv-red-50: #fef2f1; /* record validation danger background */
  --rv-red-700: #ba1c1c; /* record validation danger */
  --rv-green-50: #edfdf3; /* record validation success background */
  --rv-green-100: #027947;
  --rv-green-700: #157f3c; /* record validation success */
  --rv-grey-text: #766f6b;
  --border-radius-pill: 9999px;
  --border-radius-medium: 12px; /* modal, cards */
  --border-radius-small: 8px; /* buttons, inputs */
  --border-radius-x-small: 4px;
  --animation-timing-linear: linear;
  --animation-timing-ease-out: ease-out;
  --animation-timing-ease-in: ease-in;
  --animation-timing-ease-in-out: ease-in-out;
  --animation-extra-slow: 500ms;
  --animation-slow: 350ms;
  --animation-normal: 250ms;
  --animation-fast: 150ms;
  --animation-extra-fast: 90ms;
  --shadow-xl: 0px 20px 25px -5px var(--color-shadow-xl);
  --shadow-lg: 0px 10px 15px -3px var(--color-shadow-lg);
  --shadow-md: 0px 4px 6px -1px var(--color-shadow-md);
  --color-input-label: var(--color-gray-900);
  --color-input-icon: var(--color-gray-600);
  --color-input-outline-error: var(--color-red-600);
  --color-input-text: var(--color-gray-900);
  --color-input-border: var(--color-gray-300);
  --color-text-white: var(--color-white);
  --color-text-disabled: var(--color-gray-300);
  --color-text-link: var(--color-primary-400);
  --color-text-secondary: var(--color-gray-600);
  --color-text-primary: var(--color-gray-900);
  --color-button-tertiary-focus: var(--color-primary-100);
  --color-button-tertiary-active: var(--color-primary-100);
  --color-button-tertiary-hover: var(--color-primary-50);
  --color-button-tertiary-icon: var(--color-primary-400);
  --color-button-tertiary-text: var(--color-primary-400);
  --color-button-secondary-gray-focus: var(--color-gray-50);
  --color-button-secondary-gray-active: var(--color-gray-50);
  --color-button-secondary-gray-hover: var(--color-gray-25);
  --color-button-secondary-gray-outline: var(--color-gray-300);
  --color-button-secondary-gray-icon: var(--color-gray-900);
  --color-button-secondary-gray-text: var(--color-gray-900);
  --color-button-secondary-gray: var(--color-white);
  --color-button-secondary-focus: var(--color-primary-100);
  --color-button-secondary-active: var(--color-primary-200);
  --color-button-secondary-hover: var(--color-primary-100);
  --color-button-secondary-icon: var(--color-primary-400);
  --color-button-secondary-text: var(--color-primary-400);
  --color-button-secondary: var(--color-primary-50);
  --color-button-disabled-icon: var(--color-gray-300);
  --color-button-disabled-text: var(--color-gray-300);
  --color-button-disabled: var(--color-gray-50);
  --color-button-primary-focus: var(--color-primary-100);
  --color-button-primary-active: var(--color-primary-900);
  --color-button-primary-hover: var(--color-primary-600);
  --color-button-primary-icon: var(--color-white);
  --color-button-primary-text: var(--color-white);
  --color-button-primary: var(--color-primary-500);
  --color-separator-primary: var(--color-gray-300);
  --color-background-secondary: var(--color-gray-50);
  --color-background-primary: var(--color-white);
  /* app v2.0 */
  --v2-color-text-primary: #1f1f1f;
  --v2-color-text-dark: #1c225e; /*select search dropdown text color */
  --v2-color-gray-dark: #666666;
  --v2-color-dropdown-bg: #f7f7f7; /*select search dropdown bg color */
  --v2-color-border: #949494; /*select search border color */
  --v2-color-dropdown-right: #dddddd; /*select search dropdown right border color */
  --v2-color-blue-hover: #ecf3ff;
  --v2-font-family-primary: HelveticaNowText;
  --v2-shadow-lg: rgba(0, 0, 0, 0.08);
  --v2-color-active: #1a6d93;
  --v2-color-white: #f1f1f1;
  --v2-grey-50: #f9f9f9;
  --v2-grey-light: #f4f4f4;
  --v2-: #fef0c8;
  --v2-careplanbtton-border: #bae4d2;
  --v2-draft-border: #d0e0ef;
  --v2-gray-200: #bbbbbb;
  --v2-midnight-blue: #132a60;
  --v2-pale-steel-blue: #b1bfd0;
  --v2-dark-gray: #333;
  --v2-deep-forest-green: #132a00;
  --v2-light-mist-blue: #e4e9f0;
  --v2-slate-blue-gray: #969db2;
  --v2-light-gray: #eaeaea;
  --v2-warm-light-gray: #e0e0df;
  --error-color: #a5341d;
  --v2-color-hover-gray: #e8e8e8;
  --v2-gray-600: #766f6b;
  --v2-primary-blue: #1a6d93;
  --v2-gray-placeholder: #78716c;
  --v2-gray-overlay: #00000080;
}
