.heading {
  margin-bottom: 24px;
}

.cards {
  display: flex;
  flex-direction: row;
}

.card {
  margin-right: 16px;
}

.spinner {
  height: 168px;
}
