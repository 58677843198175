.resourceContainer {
  flex-shrink: 0;
  position: relative;
  width: 180px;
  height: 183px;
  background-color: var(--color-background-secondary);
  border-radius: var(--border-radius-x-small);
  overflow: hidden;
  cursor: pointer;
}

.photoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;
  position: relative;
  background-color: var(--color-text-disabled);
}

.photoContainer > img {
  object-fit: cover;
  position: absolute;
  border: none;
  outline: none;
}

.photoOverlay {
  position: absolute;
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
}

.resourceFooter {
  padding: 12px 16px;
}

.removeIconContainer {
  border: none;
  outline: none;
  padding: none;
  margin: none;
  background: transparent;
  cursor: pointer;
  position: absolute;
  top: 12px;
  right: 12px;
}

.caption,
.title {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
