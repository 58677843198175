.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  border-radius: 10px;
  justify-content: space-between;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.header button {
  background-color: var(--color-white);
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.header .icon {
  display: flex;
  align-items: center;
}

.header .icon img {
  width: 20px;
  margin-right: 10px;
}

.header h1 {
  font-size: 24px;
  font-weight: bold;
  margin: 0;
}

.header p {
  margin: 0;
  font-size: 14px;
  color: #666;
}

.content {
  flex: 1;
  background-color: var(--color-white);
  padding: 32px 80px 40px 80px;
  border-bottom: 1px solid var(--v2-gray-200);
}

.guide h2 {
  font-size: 18px;
  font-weight: bold;
  margin: 0;
}

.guide p {
  margin: 0;
  font-size: 14px;
  color: #666;
}

.guide .buttons {
  display: flex;
  align-items: center;
}

.guide .buttons button {
  background-color: var(--color-white);
  border: 1px solid #ccc;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 16px 80px 16px 80px;
  border-top: 1px solid var(--v2-gray-200);
}

.footer .buttons {
  display: flex;
  align-items: center;
}

.footer .buttons button:nth-child(1) {
  color: #1a6d93;
  background-color: var(--color-primary-50);
  width: 115px;
}

.footer .buttons button:nth-child(2) {
  width: 94px;
}

.footer .buttons button {
  padding: 0px 12px 0px 16px;
  height: 40px;
  border-radius: 8px;
  margin-left: 10px;
  border: none;
  outline: none;
}

.buttonDeactivate {
  color: var(--color-gray-300) !important;
  background-color: var(--color-gray-50) !important;
}

.publishModal {
  width: 443px;
}
.buttonGroup,
.selectHeading {
  margin-top: 32px;
}
.publishModal,
.publishModal .contentContainer {
  overflow-y: visible;
}

.addNewButton {
  height: 40px;
  padding: 0px 16px;
  border-radius: 8px;
}

.modalFormat {
  height: 281px;
  width: 443px;
  overflow: visible;
  margin-top: 20px;
}
