.record {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-grow: 1;
}

.recordSections {
  display: flex;
  flex-direction: column;
  /* gap: 16px; */
  /* background-color: var(--color-gray-25); */
  background-color: var(--color-white);
  flex: 1 1 1px;
  overflow: auto;
  padding-bottom: 50vh;
  scroll-behavior: smooth;
}

.section {
  display: flex;
  flex-direction: column;
  background-color: var(--color-white);
  flex-shrink: 0;
  padding: 64px 32px 0px 32px;
}

.topSection {
  display: flex;
  flex-direction: column;
  background-color: var(--color-white);
  flex-shrink: 0;
  padding: 32px 32px 0px 32px;
}

.sectionBorderBottom {
  display: flex;
  flex-direction: column;
  background-color: var(--color-white);
  flex-shrink: 0;
  padding: 48px 32px 16px 32px;
}

.borderBottom {
  border-bottom: 1px solid var(--color-separator-primary);
  width: 100%;
}

.heading {
  margin-bottom: 24px;
}

.body {
  margin-bottom: 48px;
}
