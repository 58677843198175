.drawerContainer {
  position: absolute;
  display: flex;
  justify-content: center;
  height: 100%;
  max-height: 100%;
  width: 100%;
  background-color: rgba(41, 37, 36, 0.7);
  z-index: 9999;
  overflow-x: hidden;
  animation: fade-in 200ms linear;
}

.drawer {
  display: flex;
  position: absolute;
  flex-direction: column;
  box-sizing: border-box;
  background-color: var(--color-white);
  padding: 0px 32px 0 32px;
  height: 100%;
  width: 900px;
  right: 0px;
  animation: slide-left 300ms ease-out;
  overflow-y: auto;
}

.alertContainer {
  margin: 0px -32px;
}

.headerTitleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}

.headerAndTag {
  align-content: center;
}

.drawerHeader {
  padding-top: 36px;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: space-between;
  gap: 16px;
  align-items: center;
  margin-bottom: 16px;
}

.contentContainer {
  /* overflow-y: scroll; */
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-height: 0px;
}

.page {
  width: 836px;
  height: 100%;
  flex-grow: 1;
  transition: all 300ms ease-in-out;
  transform: translateX(0px);
  position: absolute;
  display: block;
}

.page.enter {
  transform: translateX(900px);
}

.page.enterActive {
  transform: translateX(0px);
}

.page.exit {
  transform: translateX(0px);
}

.page.exitActive {
  transform: translateX(900px);
}

.page.hide {
  transform: translateX(0px);
}

.page.hideActive {
  transform: translateX(-900px);
}

.page.hideDone {
  transform: translateX(-900px);
}

.page.appear {
  transform: translateX(-900px);
}

.page.appearActive {
  transform: translateX(0px);
}

.closing.drawerContainer {
  animation: fade-out 200ms linear forwards;
}

.closing > .drawer {
  animation: slide-right 300ms ease-out forwards;
}

@keyframes fade-in {
  from {
    background-color: transparent;
  }
}

@keyframes fade-out {
  to {
    background-color: transparent;
  }
}

@keyframes slide-left {
  from {
    right: -900px;
  }
}

@keyframes slide-right {
  to {
    right: -900px;
  }
}

@media screen and (max-width: 1024px) {
  .drawer {
    width: 768px;
  }
}
