.appointmentDetailCard {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: space-between; */
  border: 1px solid var(--color-gray-300);
  box-sizing: border-box;
  border-radius: var(--border-radius-small);
  background-color: var(--color-white);
  padding: 24px;
  width: 836px;
  height: 220px;
}

.drawer {
  width: 100%;
  overflow-y: auto;
}

.row1 {
  display: flex;
  padding-bottom: 24px;
  justify-content: space-between;
  align-items: center;
}

.row2 {
  display: flex;
  padding-bottom: 24px;
  justify-content: space-between;
  align-items: flex-start;
}

.tag {
  padding-bottom: 8px;
}

.tagSpace {
  padding-right: 12px;
}

.apptTags {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.newTab {
  text-decoration: none;
  color: var(--color-primary-400);
}

.apptDetail {
  display: flex;
  flex-direction: row;
}

.providerModal {
  height: 700px;
}

@media screen and (max-width: 1024px) {
  .appointmentDetailCard {
    width: 700px;
  }
}
