.page {
  width: 836px;
  height: 100%;
  flex-grow: 1;
  transition: all 300ms ease-in-out;
  transform: translateX(0px);
  position: absolute;
  display: block;
}

@media screen and (max-width: 1024px) {
  .page {
    width: 100%;
  }
}
