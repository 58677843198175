.availabilityPage {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  height: min-content;
  flex: 1 1 1px;
}

.mainContent {
  display: flex;
  flex-direction: column;
  /*justify-content: space-between;*/
  align-items: flex-start;
  padding: 32px;
  padding-bottom: 0px;
  height: 100%;
  overflow-y: auto;
}

.availabilityManagement {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  height: 79px;
  margin-bottom: 22px;
}

.availabilityManagementHeader {
  margin-right: 227px;
  width: 311px;
  height: 34px;
}

.availabilityManagementHeaderCareNav {
  margin-bottom: 67px;
  margin-right: 227px;
  width: 311px;
  height: 34px;
}

.weeklyHoursAndOverride {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 64px;
  margin-right: 17px;
}

.epicUser {
  width: 100%;
  height: 37px;
  background-color: var(--color-primary-400);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.contentSeparator {
  height: 1px;
  background-color: var(--color-separator-primary);
}

.experianCodePanelWrapper {
  padding: 30px;
}
