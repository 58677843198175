.interactiveCard {
  background-color: var(--color-background-primary);
  padding: 16px;
  display: flex;
  gap: 24px;
  width: auto;
  box-sizing: border-box;
  border-radius: var(--border-radius-x-small);
  border: 1px solid var(--color-separator-primary);
  cursor: pointer;
  user-select: none;
}

.interactiveCard:hover > .contentContainer > .contentBody {
  color: var(--color-text-link);
}

.interactiveCard:hover:not(.checked) {
  border: 1px solid var(--color-gray-400);
}

.interactiveCard.checked {
  border: 1px solid var(--color-primary-400);
}

.interactiveCard.checked:hover {
  border: 1px solid var(--color-primary-500);
}

.contentContainer {
  flex: 1 1 1px;
  display: flex;
  flex-direction: column;
}

.cardTitle {
  color: var(--color-text-link);
}

.contentBody {
  margin-top: 4px;
  flex-grow: 1;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.caption {
  margin-top: 8px;
}

.tagsContainer {
  display: flex;
  flex-direction: row;
  gap: 8px;
  flex-wrap: wrap;
  margin-top: 12px;
}

.imageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;
  width: 120px;
  position: relative;
  border-radius: var(--border-radius-x-small);
  background-color: var(--color-text-disabled);
  overflow: hidden;
}

.imageContainer > img {
  object-fit: cover;
}

.imageOverlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
}

.sharedIcon {
  position: absolute;
  right: 8px;
  bottom: 8px;
}

.type {
  text-transform: capitalize;
}
