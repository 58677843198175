.appointmentCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: var(--border-radius-small);
  background-color: var(--color-white);
  border: 1px solid var(--color-gray-300);
  overflow: hidden;
  gap: 0px;
  width: 864px;
  min-height: 184px;
}

.appointmentInfoContainer {
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 24px 24px 24px 24px;
  flex-direction: row;
}

.checkingInWith {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: var(--color-background-secondary);
  height: 36px;
}

.updateCareCoordinatorButton {
  cursor: pointer;
  user-select: none;
}

.newTab {
  text-decoration: none;
  color: var(--color-primary-400);
}

.time {
  display: flex;
  flex-direction: column;
  width: 120px;
  margin-top: 21px;
}

.button {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.column {
  justify-content: space-between;
  width: 100%;
}

.noCarePlan {
  padding-top: 24px;
}

.row {
  display: flex;
  justify-content: left;
  align-items: flex-start;
}

.tag {
  padding-bottom: 8px;
}

.tagSpace {
  padding-right: 12px;
}

.buttonsContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.apptButton {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.apptTags {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-top: 10px;
}

.apptDetail {
  display: flex;
  flex-direction: row;
}

.avatar {
  position: relative;
  color: var(--color-text-white);
  display: flex;
  justify-content: center;
  align-items: top;
  flex-shrink: 0;
  background-color: var(--color-primary-500);
  border-radius: var(--border-radius-pill);
}

.avatar img {
  border-radius: var(--border-radius-pill);
}

.online::after {
  content: "";
  width: calc(100% / 4);
  height: calc(100% / 4);
  border: 2px solid var(--color-white);
  border-radius: var(--border-radius-pill);
  background-color: var(--color-green-600);
  position: absolute;
  bottom: 0;
  right: 0;
}

.photo {
  background-color: transparent;
}

.initials {
  background-color: var(--color-primary-500);
}

.md {
  font-size: 16px;
  width: 40px;
  height: 40px;
}

.avatarLabelGroup {
  flex-shrink: 1;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: top;
  gap: 12px;
  max-width: 100%;
  min-width: 0px;
}

.infoContainer {
  display: flex;
  min-width: 0;
  flex-direction: column;
}

.nameContainer {
  display: flex;
  justify-content: flex-start;
  gap: 4px;
  font-weight: var(--font-weight-bold);
  line-height: var(--line-height-small);
}

.names {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.subText {
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-medium);
  color: var(--color-text-secondary);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.modal {
  height: 700px;
}

.sm .names {
  font-size: 14px;
}

.md .names {
  font-size: 16px;
}

.lg .names {
  font-size: 18px;
}

.xl .names {
  font-size: 18px;
}

.xxl .names {
  font-size: 18px;
}

.sm .subText {
  font-size: 12px;
}

.md .subText {
  font-size: 12px;
}

.lg .subText {
  font-size: 14px;
}

.xl .subText {
  font-size: 14px;
}

.xxl .subText {
  font-size: 14px;
}

@media screen and (max-width: 1024px) {
  .appointmentInfoContainer {
    flex-wrap: wrap;
  }
  .time {
    margin-top: 0px;
    justify-content: center;
  }
  .patientAvatar {
    width: calc(100% - 120px);
  }
  .btnStatusWrapper {
    display: flex;
    margin-top: 20px;
  }
  .apptTags {
    padding-top: 0;
    flex-direction: row;
    align-items: center;
    gap: 15px;
  }
  .tagResp {
    padding-bottom: 0;
  }
}
