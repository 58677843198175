.table {
  padding: 32px 32px 0px 32px;
}

.hint {
  padding-top: 20px;
  padding-bottom: 32px;
}

.duplicateFileError {
  padding-top: 20px;
  padding-bottom: 8px;
}

.attachments,
.attachmentsSubtext {
  margin-top: 32px;
  margin-bottom: 32px;
  flex-wrap: wrap;
  display: flex;
  gap: 32px;
}
