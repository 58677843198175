/* base style */

.button {
  display: flex;
  position: relative;
  width: min-content;
  justify-content: center;
  align-items: center;
  border-radius: var(--border-radius-small);
  padding-left: 16px;
  padding-right: 16px;
  border: none;
  outline: none;
  cursor: pointer;
}

.buttonGroup {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 12px;
  overflow: visible;
}

.buttonGroup.vertical {
  flex-direction: column;
}

.buttonGroup.start {
  justify-content: flex-start;
}

.buttonGroup.center {
  justify-content: center;
}

.buttonGroup.end {
  justify-content: flex-end;
}

.buttonInner {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  white-space: nowrap;
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-bold);
  line-height: var(--line-height-reset);
}

.buttonInner.loading {
  opacity: 0;
}

/* primary style */

.primary {
  color: var(--color-button-primary-text);
  background-color: var(--color-button-primary);
}

.primary:hover {
  background-color: var(--color-button-primary-hover);
}

.primary:active {
  background-color: var(--color-button-primary-active);
}

.primary:focus {
  box-shadow: 0px 0px 0px 4px var(--color-button-primary-focus);
}

/* secondary style */

.secondary {
  color: var(--color-button-secondary-text);
  background-color: var(--color-button-secondary);
}

.secondary:hover {
  background-color: var(--color-button-secondary-hover);
}

.secondary:active {
  background-color: var(--color-button-secondary-active);
}

.secondary:focus {
  box-shadow: 0px 0px 0px 4px var(--color-button-secondary-focus);
}

/* secondary-gray style */

.secondary-gray {
  color: var(--color-button-secondary-gray-text);
  background-color: var(--color-button-secondary-gray);
  border: 1px solid var(--color-button-secondary-gray-outline);
}

.secondary-gray:hover {
  background-color: var(--color-button-secondary-gray-hover);
}

.secondary-gray:active {
  background-color: var(--color-button-secondary-gray-active);
}

.secondary-gray:focus {
  box-shadow: 0px 0px 0px 4px var(--color-button-secondary-gray-focus);
}

/* tertiary style */

.tertiary {
  color: var(--color-button-tertiary-text);
  background-color: var(--color-button-tertiary);
}

.tertiary:hover {
  background-color: var(--color-button-tertiary-hover);
}

.tertiary:active {
  background-color: var(--color-button-tertiary-active);
}

.tertiary:focus {
  box-shadow: 0px 0px 0px 4px var(--color-button-tertiary-focus);
}


/* transparent style */

.transparent {
  /* color: var(--color-button-secondary-gray-text); */
  border: 0;
  color: #666666;
}

.transparent, 
.transparent:hover, 
.transparent:active, 
.transparent:focus {
  background-color:transparent;
}

/* disabled style */

.disabled {
  color: var(--color-gray-300);
  background-color: var(--color-gray-50);
  border: none;
  cursor: default;
  pointer-events: none;
}

/* sizing style */

.small {
  height: 36px;
  font-size: 14px;
}

.medium {
  height: 48px;
  font-size: 16px;
}

/* icon only */

.iconOnly {
  padding: 12px;
}

.small.iconOnly {
  height: 36px;
  width: 36px;
}

.medium.iconOnly {
  height: 48px;
  width: 48px;
}

.spinner {
  position: absolute;
  animation-name: spin;
  animation-duration: 700ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
