.spinnerContainer {
  position: relative;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.spinner {
  position: absolute;
  top: 64px;
  animation-name: spin;
  animation-duration: 700ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
