.includePatientForm > button {
  /* display:block; */
  padding: 32px;
  margin: 16px 0px 0px 0px;
  width: 100%;
  outline: none;
  border: none;
  background-color: var(--color-button-secondary);
  cursor: pointer;
  border-radius: var(--border-radius-small);
}

.includePatientForm > button:hover {
  background-color: var(--color-button-secondary-hover);
}

.includePatientForm > button:active {
  background-color: var(--color-button-secondary-active);
}

.includePatientForm > button:focus {
  box-shadow: 0px 0px 0px 4px var(--color-button-secondary-focus);
}

.prompt {
  margin-bottom: 8px;
}
