.searchSelect {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 8px;
}

.searchSelect + button {
  margin-left: 15px;
}
.inputDropdownWrap {
  position: relative;
}

.inputContainer {
  position: relative;
  z-index: 20;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 0px 15px 0px 0;
  gap: 8px;
  min-height: 48px;
  border: 1px solid var(--color-separator-primary);
  border-radius: var(--border-radius-small);
  background-color: var(--color-white);
}

.inputContainer.focused {
  border-color: var(--color-primary-400);
}

.inputContainer.error {
  border-color: var(--color-red-600);
}

.inputContainer:hover:not(.focused) {
  border-color: var(--color-gray-400);
}

.inputTextContainer {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  min-width: 0;
}

.inputTextContainer::-webkit-scrollbar {
  display: none;
}

.input {
  display: inline;
  flex-grow: 1;
  min-width: 64px;
  min-width: fit-content;
  padding: 0;
  margin: 0;
  font-family: ProximaNova;
  font-size: 16px;
  line-height: var(--line-height-medium);
  font-weight: var(--font-weight-regular);
  color: var(--color-text-primary);
  border: none;
  outline: none;
  resize: none;
}

.input::placeholder {
  color: var(--color-text-secondary);
}

.input:disabled,
.input:disabled::placeholder {
  color: var(--color-text-disabled);
  background-color: white;
}

.icon {
  display: flex;
  flex: 1;
  align-items: center;
  max-width: 24px;
  width: 24px;
  height: 24px;
  color: var(--color-text-secondary);
  cursor: pointer;
}

.activeIcon {
  color: var(--v2-color-text-primary);
}

.clearButton {
  display: flex;
  flex: 1;
  padding: 0;
  width: 20px;
  min-height: 24px;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  color: var(--color-text-secondary);
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
}
.iconDisabled {
  opacity: 0.5;
  pointer-events: none;
}
.resultCount {
  font-family: ProximaNova;
  font-size: 11px;
  font-weight: 400;
  color: var(--v2-color-gray-dark);
}
.pl10 {
  padding-left: 10px;
}

.label {
  display: block;
  font-size: 14px;
  line-height: var(--line-height-reset);
  font-weight: var(--font-weight-medium);
  letter-spacing: var(--font-tracking-none);
}

.patientSeachPadding {
  padding: 0px 15px 0px 0px;
}

.inputContainerPadding {
  padding: 11px 15px;
}
