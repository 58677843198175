.heading {
  padding-bottom: 24px;
}

.book {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 32px 0;
}

.buttonGroup {
  padding-left: 32px;
}
